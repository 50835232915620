import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { SINGLE_PARLOUR_DETAILS } from '../../services/crm/user.service';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import BasicTable from './BasicTable';
import { FaFileUpload } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useEffect } from 'react';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxHeight: 'calc(100vh - 50px)',
  overFlowX: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const months = [
    "January", "February", "March", "April", "May", "June", 
"July", "August", "September", "October", "November", "December"
];
const years = [
  "2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009",
  "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019",
  "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029",
  "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039",
  "2040", "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049", "2050"
];
export default function TransitionsModal({open,setOpen,handleOpen,handleClose,newUpload,salonId}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  style.width = isMobile?"80%":"auto";

    const headings = ["File Name", "Uploaded PDF","Uploaded Date"]
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const [month, setMonth] = useState();
      const [year, setYear] = useState();
      const [selectedParlorDetail,setSelectedParlorDetail] =useState([]);
    // 
    useEffect(()=>{
        setMonth(months[currentMonth])
        setYear(currentYear.toString())
    },[open])
    useEffect(()=>{
        const fetchApi=async()=>{
            const updatingDate = new Date(Date.UTC(year, months.indexOf(month)));
            const selectedDate = updatingDate;
            const singleParlourDetails = await SINGLE_PARLOUR_DETAILS({salonId,selectedDate});
            if(singleParlourDetails){
                setSelectedParlorDetail(singleParlourDetails)
            }
        }
        fetchApi()
    },[open,month,year,newUpload])
    const handlePdfUrl=(url)=>{
        window.open(url, '_blank');
    }
    function createData(name, url,date) {
        return { name, url,date };
      }
      const rows = selectedParlorDetail?.map((item)=>createData(item.fileName,<FaFileUpload className='text-xl font-bold text-right cursor-pointer' onClick={() => handlePdfUrl(item.invoiceUrl)}/>,item?.createdAt))
    // 
    // 
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className='w-[90%] mx-auto flex  justify-between'>
                <MonthPicker months={months} month={month} setMonth={setMonth}/>
                <YearPicker years={years} year={year} setYear={setYear}/>
            </div>
            <BasicTable headings={headings} rows={rows}/>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
